import { UserFunctionsEnum } from "../../app/models/enums/enums";
import { UserModel } from "../../app/models/interfaces/user-model";

//method to prepare date param for our PHP API
export function removeMillisecondsFromDateString(dateStr: string): string {
    let dateWithoutMillis = dateStr.split('.')[0];
    let lastChar = dateStr.charAt(dateStr.length - 1);
    return dateWithoutMillis + lastChar;
}

export function prepareUserFunctionsArray(user: UserModel): UserFunctionsEnum[] {
    let functions = user?.userFunctions || [];
    if (user?.isQuasiUser) {
        functions = [UserFunctionsEnum.QUASI];
    }
    if (user?.hasLearningMaterials) {
        functions = [...functions, UserFunctionsEnum.LEARNING_MATERIALS];
    }
    return functions;
}

export function prepareUrlDateParameter(date: Date): string {
    const dateString = date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
    }).replace(' ', 'T').replace('kl. ', '');

    return `${dateString}`;
}

export function prepareDateWithHourParameter(date: Date): string {
    const dateString = date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    }).replace(' ', 'T').replace('kl. ', '');

    return `${dateString}`;
}

function formatDateWithTimezone(date: Date): string {
    const MINUTES_IN_HOUR = 60;
    const dateString = prepareDateWithHourParameter(date);
    // Get the timezone offset in hours and minutes
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / MINUTES_IN_HOUR)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % MINUTES_IN_HOUR).padStart(2, '0');
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';
    const formattedOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;

    return `${dateString}${formattedOffset}`;
}

// Main function: prepares the date parameter without changing the date
export function prepareDateApiParameter(date: Date): string {
    return formatDateWithTimezone(date);
}

// Main function: prepares the date parameter by setting the date to the previous day at 23:59
export function prepareDateToApiParameter(date: Date): string {
    const previousDay = new Date(date);
    previousDay.setDate(date.getDate() - 1);
    previousDay.setHours(23, 59, 59, 0); // Set hours to 23, minutes to 59, and seconds to 0
    return formatDateWithTimezone(previousDay);
}
